import { CircularProgress } from '@mui/material'
import { Block, BlockMap, ExtendedRecordMap } from 'notion-types'
import { getPageBreadcrumbs, getPageTitle } from 'notion-utils'
import { useEffect, useState } from 'react'
import { NotionRenderer } from "react-notion-x"
import { Link, useLocation, useParams } from 'react-router-dom'
import fetchNotionData from '../api/fetchNotionData'

import parse, { Element, domToReact } from 'html-react-parser'
import { renderToString } from "react-dom/server"
import { trackPageView } from '../tracking/Hubspot'
import {Helmet} from "react-helmet";

export type BreadCrumb = {
    active: boolean
    block: Block
    icon: string
    pageId: string
    title: string
}
type Props = {
    urls: { [notionId: string]: string }
}
function SupportArticle({urls}: Props) {
    let { id } = useParams()

    const topLevelNotionBlockId = 'ff988016-3346-40fe-8ce6-be558f686df0'
    const [notionContent, setNotionContent] = useState<ExtendedRecordMap | null>(null)
    const [loading, setLoading] = useState(true)
    const [breadcrumbs, setBreadcrumbs] = useState<BreadCrumb[] | null>(null)
    const [editedTime, setEditedTime] = useState<string | null>(null)
    const location = useLocation()

    useEffect(() => {
        trackPageView()
    }, [location])

    useEffect(() => {
        setLoading(true)
        const controller = new AbortController();

        let articleId = id || topLevelNotionBlockId
        const notionId = Object.keys(urls).find(key => urls[key] === articleId)

        if(notionId) {
            articleId  = notionId
        }

        fetchNotionData(articleId || topLevelNotionBlockId, controller).then((data) => {
            const filteredBlocks: BlockMap = {}
            Object.values(data.block).forEach(block => {
                if (block.value.type === 'image' && block.value.version === 2) {
                    console.log('Ignoring block ' + block.value.id)
                } else {
                    filteredBlocks[block.value.id] = block
                }
            })
            setNotionContent({ ...data, block: filteredBlocks })
            setBreadcrumbs(getPageBreadcrumbs(data, articleId || topLevelNotionBlockId))
            setLoading(false)
            const latestEdited = Object.values(data.block).sort((a, b) => {
                return new Date(b.value.last_edited_time).getTime() - new Date(a.value.last_edited_time).getTime()
            })[0]
            setEditedTime(new Date(latestEdited?.value?.last_edited_time).toLocaleDateString('nb-NO', { year: 'numeric', month: 'long', day: 'numeric' }))
            document.title = "Fixrate Support – " + getPageTitle(data)
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })

        return () => controller.abort();
    }, [id])

    return (
        <div>
            {loading && <CircularProgress />}
            {!loading && (
                <>
                    {breadcrumbs && breadcrumbs.length > 1 &&
                        <ul className='breadcrumbs'>
                            <li className='noDecorate'>
                                <Link className='flex' to="/">
                                    <i className='ri-home-line'/>
                                    <span style={{textDecoration: 'underline'}}>Hjem</span>
                                </Link>
                            </li>
                            {breadcrumbs.map((breadcrumb, index) => (
                                index > 0 && (
                                    <li key={breadcrumb.pageId}>
                                        {index === breadcrumbs.length - 1 && (
                                            <span>{breadcrumb.title}</span>
                                        )}
                                        {index !== breadcrumbs.length - 1 && (
                                            <Link to={"/" + urls[breadcrumb.pageId]}>{breadcrumb.title}</Link>
                                        )}
                                    </li>
                                )
                            ))}
                        </ul>
                    }
                    { notionContent && (
                        <>
                            <Helmet>
                                <title>{getPageTitle(notionContent)}</title>
                            </Helmet>
                            <h1>{getPageTitle(notionContent)}</h1>
                            <p className='last-edited'>
                                <i className='ri-time-line'/>
                                <span>Sist endret {editedTime}</span>
                            </p>
                            {parseWithLinks(renderToString(<NotionRenderer mapPageUrl={(pageId) => '/' + urls[pageId]} isImageZoomable={false} recordMap={notionContent}/>))}
                        </>
                    )}
                </>
            )}
        </div>
    )
}

function parseWithLinks(text: string) {
    const options: any = {
        replace: ({ name, attribs, children }: Element) => {
            if (name === 'a' && attribs.href && attribs.href.startsWith('/')) {
                return <Link to={attribs.href} className={attribs.class}>{domToReact(children)}</Link>;
            }
        }
    };
    return parse(text, options);
}

export default SupportArticle

