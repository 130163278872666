import {Box, Button, List, ListItemButton, ListItemText, useMediaQuery} from "@mui/material";
import {getPageBreadcrumbs} from 'notion-utils';
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import fetchNotionData from "../api/fetchNotionData";

type Props = {
    setMenuOpen: (open: boolean) => void
    urls: { [notionId: string]: string }
}
export const MenuList = ({setMenuOpen, urls}: Props) => {
    let {id} = useParams();
    const [loading, setLoading] = useState(false)
    const [menu, setMenu] = useState<any>(null)
    const [activeTopLevelId, setActiveTopLevelId] = useState<string | null>(null)
    const topLevelNotionBlockId = 'ff988016-3346-40fe-8ce6-be558f686df0'
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width:600px)')
    useEffect(() => {
        setLoading(true)
        const controller = new AbortController();
        fetchNotionData(topLevelNotionBlockId, controller).then((data) => {
            setMenu(data.block)
            setLoading(false)
            console.log(data)
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })

        return () => {
            controller.abort();
        }
    }, [])

    useEffect(() => {
        const controller = new AbortController();
        if (id && id !== activeTopLevelId) {
            setLoading(true)
            const n = Object.keys(urls).find(key => urls[key] === id)
            fetchNotionData(id, controller).then((data) => {
                const breadcrumbs = getPageBreadcrumbs(data, n || 'ff988016-3346-40fe-8ce6-be558f686df0')
                if (breadcrumbs && breadcrumbs.length > 1) {
                    setActiveTopLevelId(breadcrumbs[1].pageId)
                } else {
                    setActiveTopLevelId(topLevelNotionBlockId)
                }
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
        }

        return () => {
            controller.abort();
        }
    }, [id])

    const navigateTo = (id: string) => {
        if (id !== "") {
            setActiveTopLevelId(id)
            navigate('/' + urls[id] || id)
        } else {
            navigate("/")
        }
        setMenuOpen(false)
    }

    return (
        <Box flexDirection={'column'} display={'flex'} height={'calc(100% - 7rem)'}>
            <List sx={{
                    width: '30rem', 
                    padding: {
                        'xs': '0',
                        'md': '4rem 0rem 2rem 0rem',
                    }
                }}>
                <ListItemButton sx={{
                    borderLeft: window.location.pathname === "/" ? '0.4rem solid #4d2a98' : '0.4rem solid transparent',
                    borderBottom: { 'xs': '0.1rem solid rgba(0,0,0,0.08)', 'md': '0' },
                    paddingTop: '0.8rem',
                    paddingBottom: '0.8rem',
                }} onClick={() => navigateTo("")}>
                    <ListItemText sx={{'& .MuiTypography-root': {fontSize: '1.4rem', fontWeight: '600'}}}
                                primary={'Oversikt'}/>
                </ListItemButton>
                {menu && Object.keys(menu).map((menuItem: string) => (
                    <ListItemButton key={menuItem} sx={{
                        borderLeft: (window.location.pathname !== "/" && activeTopLevelId === menuItem) ? '0.4rem solid #4d2a98' : '0.4rem solid transparent',
                        borderBottom: { 'xs': '0.1rem solid rgba(0,0,0,0.08)', 'md': '0' },
                        paddingTop: '0.8rem',
                        paddingBottom: '0.8rem',
                        display: menuItem === topLevelNotionBlockId ? 'none' : null,
                    }} onClick={() => !loading && navigateTo(menuItem)}>
                        <ListItemText sx={{'& .MuiTypography-root': {fontSize: '1.4rem', fontWeight: '600'}}}
                                    primary={menu[menuItem]?.value?.properties?.title[0][0]}/>
                    </ListItemButton>
                ))}
            </List>
            { isMobile && ( 
            <Button variant="contained" sx={{mt: 'auto'}} fullWidth onClick={() => window.location.replace('https://fixrate.no')}>
                Gå til fixrate.no
            </Button>
            )}
        </Box>
    )
}
