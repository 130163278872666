
import { Box, Container } from "@mui/material";
import React, { useState } from "react";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { SideMenu } from "./components/SideMenu";

type Props = {
    element: React.ReactNode;
    urls: { [notionId: string]: string }
}

export const Main = ({element,urls}: Props) => {
    const [menuOpen, setMenuOpen] = useState(false)

    return (
        <>
            <Header urls={urls} toggleMenuOpen={() => setMenuOpen(!menuOpen)}/>
            <SideMenu setMenuOpen={setMenuOpen} menuOpen={menuOpen} urls={urls}/>
            <Container maxWidth='lg' sx={{
                marginLeft: {
                    md: '40rem'
                },
                marginBottom: {
                    md: '4rem',
                },
                marginTop: {
                    md: '12rem',
                    xs: '9rem'
                },
                width: {
                    md: 'calc(100% - 40rem)',
                },
                display: 'flex',
                backgroundColor: '#fff',
            }}>

                <Box sx={{padding: {
                    xs: '1rem',
                    sm: '2rem',
                    md: '0rem 2rem 2rem 2rem',
                    width: '100%',
                }}}>
                    {element}

                </Box>
            </Container>
            <Footer/>
        </>
    )
}
