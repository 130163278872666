import { Card, CardActionArea, CardContent, CardMedia, Dialog } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'remixicon/fonts/remixicon.css';
import "./App.scss";
import heroImage from './images/support-hero-image.svg';

const menu = [
    {
        id: "bf19363c-a9b2-4468-b49a-e34edaa18eaa",
        title: "Kom i gang",
        description: "Jeg ønsker å komme i gang med Fixrate",
        icon: "ri-flashlight-line",
    },
    {
        id: "37cd1c81-5900-40af-b5d8-f072992a531b",
        title: "Annonsene på markedsplassen",
        description: "Lær mer om hvordan annonsene fungerer",
        icon: "ri-notification-badge-line",
    },
    {
        id: "38509b86-09c8-4595-893e-ea1d63c73418",
        title: "Betingelser og vilkår",
        description: "Se hvilke betingelser som er gjeldende",
        icon: "ri-asterisk",
    },
    {
        id: "aba67942-2e9c-4c3c-a64b-cdd0ef52385e",
        title: "Portefølje og rapportering",
        description: "Lær mer om rapportering fungerer",
        icon: "ri-article-line",
    },
    {
        id: "1fbfbc92-3c54-4815-b25a-27470889ada6",
        title: "Roller og rettigheter",
        description: "Lær mer om rollesystemet på Fixrate",
        icon: "ri-group-line",
    },
    {
        id: "070a8270-f4de-463e-b492-e4361d775446",
        title: "Kundeerklæring",
        description: "Les mer om reelle rettighetshavere og PEP",
        icon: "ri-file-user-line",
    },
    {
        id: "c1b20348-5c39-4129-9887-7ddf6e0e84e6",
        title: "Sikkerhet og personvern",
        description: "Les mer om sikkerhet og personvern i løsningen",
        icon: "ri-shield-check-line",
    },
    {
        id: "7f94cc998d72419a8152b463a4f0f6b3",
        title: "Bærekraft i Fixrate",
        description: "Les mer om bærekraft i Fixrate",
        icon: "ri-leaf-line",
    }
]

//Get thumbnails from Vimeo:
//https://vimeo.com/manage/videos

const videos = [
    {
        id: "791178985",
        thumbnail: "https://i.vimeocdn.com/video/1591916475-4e97e53b71d976842023276227633b0b3f17c97bd4c31d91fcc5a9d5c2a22a96-d_472x266",
        title: "Gjennomføring av bestilling",
        description: "Vi viser deg hvordan du gjennomfører en bestilling på Fixrate",
    },
    {
        id: "676301306",
        thumbnail: "https://i.vimeocdn.com/video/1370513581-667b222440c2118b86017acf8e9a39a754351c67e7f74fc87867860985ce08ed-d_472x266",
        title: "Hvordan fungerer Fixrate?",
        description: "Få en kort introduksjon til hvordan Fixrate fungerer",
    },
]

type Props = {
    urls: { [notionId: string]: string }
}

function Home({urls}: Props) {
    const navigate = useNavigate()
    const [videoId, setVideoId] = useState<string | null>(null)
    const [showVideo, setShowVideo] = useState(false)
    const openVideo = (id: string) => {
        setVideoId(id)
        setShowVideo(true)
    }
    return (
        <>
            <div className="hero">
                <div>
                    <h1>Velkommen til Fixrate Support!</h1>
                    <ul>
                        <li>
                            <i className="ri-smartphone-line"/>
                            <span>+47 472 36 862</span>
                        </li>
                        <li>
                            <i className="ri-mail-line"/>
                            <span>support@fixrate.no</span>
                        </li>
                    </ul>
                </div>
                <img src={heroImage} alt=""/>
            </div>
            <h2>Hva trenger du hjelp med?</h2>
            <ul className='card-list'>
                {menu.map(item => (
                    <li key={item.id}>
                        <Card variant="outlined" className='fixrate-card-list-item'>
                            <CardActionArea onClick={() => navigate(urls[item.id] || item.id)} sx={{display: 'flex'}}>
                                <i className={item.icon}/>
                                <CardContent sx={{padding: '2rem'}}>
                                    <h3>
                                        {item.title}
                                    </h3>
                                    <p>{item.description}</p>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </li>
                ))}
            </ul>
            <h2>Videoer</h2>
            <Dialog onClose={() => setShowVideo(false)} sx={{'& .MuiPaper-root': { aspectRatio: '16/9'}}} open={showVideo} maxWidth={'md'}>
                { videoId && <iframe className='movie-iframe' src={"https://player.vimeo.com/video/" + videoId + "?h=b6b981ad6d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Fixrate &amp;ndash; Hvordan fungerer Fixrate"/> }
            </Dialog>
            <ul className='card-list'>
                { videos.map(video => (
                    <li key={video.id}>
                        <Card sx={{ 
                            maxWidth: '100%', 
                            backgroundColor: '#f9f9f9',
                            height: '100%',
                        }} className="fixrate-card-list-item">
                            <CardActionArea onClick={() => openVideo(video.id)}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={video.thumbnail}
                                    alt={video.title}
                                />
                                <CardContent>
                                    <h3>
                                        {video.title}
                                    </h3>
                                    <p>
                                        {video.description}
                                    </p>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </li>
                ))}
            </ul>
            <div className='flex'>

            </div>
        </>
    )
}

export default Home;
