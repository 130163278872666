import { createTheme } from "@mui/material";
import { LinkProps } from '@mui/material/Link';
import React from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

const LinkBehavior = React.forwardRef<
    HTMLAnchorElement,
    Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
    >((props, ref) => {
    const { href, ...other } = props;
    return <RouterLink ref={ref} to={href} {...other} />;
});

export const theme = createTheme(
    {
        palette: {
            primary: {
                main: '#4d2a98',
            },
            secondary: {
                main: '#21362C'
            },
            error: {
                main: '#B44A06'
            },
            warning: {
                main: '#B44A06'
            },
            success: {
                main: '#40a251'
            },
        },
        shape: {
            borderRadius: 0
        },
        typography: {
            button: {
                textTransform: 'none',
                fontSize: '1.6rem',
                fontWeight: '600'
            },
            htmlFontSize: 10,
            fontWeightRegular: 500,
            fontFamily: [
                '\'Montserrat\'',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
        spacing: 10,
        components: {
            MuiAlertTitle: {
                styleOverrides: {
                    root: {
                        fontWeight: '700',
                        marginBottom: '0.1rem',
                    }
                }
            },
            MuiButton: {
                defaultProps: {
                    LinkComponent: LinkBehavior,
                },
                styleOverrides: {
                    // Name of the slot
                    root: {
                        paddingTop: '1rem',
                        paddingBottom: '1rem',
                    },
                    contained: {
                        boxShadow: '0rem 0.1rem 0.1rem rgba(0, 0, 0, 0.4)',
                    },
                    sizeSmall: {
                        paddingTop: '0.4rem',
                        paddingBottom: '0.4rem',
                    }
                },
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        padding: '2rem 2.4rem',
                    }
                }
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        padding: '1rem',
                        fontSize: '1.2rem',
                        textAlign: 'center',
                        maxWidth: '25rem',
                        backgroundColor: 'rgba(0,0,0,0.8)',
                        boxShadow: '1px 1px 2px rgba(0,0,0,0.2)'
                    },
                    arrow: {
                        '&:before': {
                            color: 'rgba(0,0,0,0.8)',
                        }
                    }
                },
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        fontSize: '2rem',
                        fontWeight: '700',
                        paddingLeft: '3rem',
                        paddingRight: '3rem',
                        position: 'relative',
                        zIndex: '1',
                    },
                },
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        paddingLeft: '3rem',
                        paddingRight: '3rem',
                        paddingTop: '2.4rem',
                        paddingBottom: '2.4rem',
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        // Some CSS
                        fontSize: '2rem',
                    },
                },
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        cursor: 'pointer',
                        textDecoration: 'none',
                        fontWeight: '600',
                        fontSize: '1.8rem',
                        display: 'flex',
                        alignItems: 'center',
                        '& i': {
                            marginLeft: '0.4rem',
                            marginRight: '0.4rem',
                        }
                    },
                },
                defaultProps: {
                    component: LinkBehavior,
                } as LinkProps,
            },
            MuiButtonBase: {
                defaultProps: {
                    LinkComponent: LinkBehavior,
                },
            },
            MuiSelect: {
                styleOverrides: {
                    select: {
                        paddingTop: '1.2rem',
                        paddingBottom: '1.2rem',
                        backgroundColor: '#fff',
                        fontSize: '1.4rem',
                    }
                },
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.05), 0px 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 1px 3px 0px rgba(0, 0, 0, 0.05)',
                        "&.Mui-expanded": {
                            margin: "0.1rem 0"
                        }
                    }
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        fontSize: '1.4rem'
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    input: {
                        paddingTop: '1.25rem',
                        paddingBottom: '1.25rem',
                        width: '30rem',
                        boxSizing: 'border-box',
                        height: 'auto',
                        '&:focus': {
                            border: '0.1rem'
                        }
                    }
                }
            },
            MuiSwitch: {
                styleOverrides: {
                    root: {}
                }
            },
            MuiTablePagination: {
                styleOverrides: {
                    displayedRows: {
                        margin: '0'
                    },
                    selectLabel: {
                        margin: '0'
                    }
                }
            },
            MuiTableSortLabel: {
                styleOverrides: {
                    root: {
                        fontWeight: '600'
                    }
                }
            },
            MuiTable: {
                styleOverrides: {
                    root: {
                        border: '0.1rem solid rgba(0,0,0,0.1)'
                    }
                }
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'rgba(0,0,0,0.05)'
                    }
                }
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        minHeight: '5rem',
                        fontSize: '1.4rem'
                    },
                    iconWrapper: {
                        fontSize: '1.8rem',
                    },

                }
            },
            MuiListItem: {
                styleOverrides: {
                    root: {
                        marginBottom: '0'
                    }
                }
            },
            MuiListItemText: {
                styleOverrides: {
                    primary: {
                        fontWeight: '600'
                    }
                }
            },
            MuiDrawer: {
                styleOverrides: {
                    root: {
                        top: '7.1rem',
                        zIndex: '1'
                    }
                }
            },
            MuiBackdrop: {
                styleOverrides: {
                    root: {
                        top: '7.1rem'
                    }
                }
            },
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        minHeight: '5rem',
                        minWidth: '5rem',
                        backgroundColor: '#f2f2f2',
                        borderRadius: '10rem',
                        marginRight: '1rem',
                        fontSize: '2rem',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }
                }
            },
            MuiAppBar: {
                styleOverrides: {
                    colorPrimary: {
                        backgroundColor: "white"
                    }
                }
            }
        },
    });