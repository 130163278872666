import config from './config'

const enabled = config().hubspot

declare global {
    interface Window {
        gtag:any;
    }
}

function loadScript(url: string) {
    const script = document.createElement('script')
    script.src = url
    script.defer = true
    script.async = true
    script.type = 'text/javascript'
    script.addEventListener('load', () => console.log('Hubspot; Script loaded', url))
    script.addEventListener('error', () => console.error('Hubspot: Script failed to load', url))
    document.head.appendChild(script)
}

export function loadHubspot() {
    if (enabled) {
        //@ts-ignore
        var _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', window.location.pathname])
        loadScript(`//js.hs-scripts.com/${config().hubspot}.js`)
    }
}

export function trackPageView() {
    if (enabled) {
        //@ts-ignore
        var _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', window.location.pathname])
        _hsq.push(['trackPageView'])

        if (window.hasOwnProperty("gtag")) {
            window.gtag('config', 'UA-104417895-1');
        }
    }
}
