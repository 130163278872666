import { AppBar, Autocomplete, Button, Grid, IconButton, InputAdornment, Stack, TextField, Toolbar, useMediaQuery } from "@mui/material"
import { SyntheticEvent, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import FixrateLogo from '../images/fixrate-logo.svg'

type SearchDocument = {
    content: string
    id: string
    title: string
}
type SearchHighlights = {
    field: "content" | "title"
    matched_tokens: Array<string>
    snippet: string
}

type SearchHits = {
    document: SearchDocument,
    highlights: Array<SearchHighlights>,
    text_match: number
}

type Props = {
    urls: { [notionId: string]: string }
    toggleMenuOpen: () => void
}

export const Header = ({toggleMenuOpen, urls} : Props) => {
    const [searchHits, setSearchHits] = useState<SearchHits[]>([])
    const [searchValue, setSearchValue] = useState<string>("")
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width:600px)')

    const searchString = (query: string) => {
        setSearchValue(query)
        fetch("/api/search?q=" + query + "&query_by=title,content&highlight_affix_num_tokens=6")
            .then((response) => response.json())
            .then((data) => setSearchHits(data.hits))
            .catch((error) => console.log(error))
    }

    return (
        <AppBar sx={{flexDirection: 'row', zIndex: '2', height: '7rem', paddingLeft: { md: '2rem' }, paddingTop: '0.3rem', paddingBottom: '0.3rem', boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)"}}>
            <Toolbar sx={{
                width: '100%', 
                paddingLeft: {
                    'xs': '1rem',
                },
                paddingRight: {
                    'xs': '1rem',
                }
            }}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid sx={{display: 'flex'}} item>
                        <IconButton sx={{display: { md: 'none' }, marginRight: '0.6rem'}} onClick={() => toggleMenuOpen()}>
                            <i className="ri-menu-3-line"/>
                        </IconButton>
                        <Link to="/" className="logo-container"><img alt="Fixrate logo" className="logo" src={FixrateLogo}/></Link>
                    </Grid>
                    <Grid sx={{maxWidth: 'calc(100% - 17rem)'}} item>
                        <Stack direction="row" spacing={2} alignItems={'center'}>
                            { !isMobile && (
                            <Button onClick={() => window.location.replace("https://fixrate.no")} size="small" variant="outlined">
                                Gå til fixrate.no
                            </Button>
                            )}
                            <Autocomplete
                                freeSolo
                                filterOptions={(option) => option}
                                options={searchValue.length > 1 ? searchHits.map((option) => option) : []}
                                sx={{width: '30rem', maxWidth: '100%', paddingLeft: '1rem'}}
                                onChange={(e: SyntheticEvent<Element, Event>, value: NonNullable<string | SearchHits>) => {
                                    if (value && typeof value !== 'string') {
                                        setSearchValue("")
                                        setSearchHits([])
                                        navigate("/" + urls[value.document.id])
                                    }
                                }}
                                disableClearable
                                getOptionLabel={(option) =>
                                    typeof option === 'string' ? option : option.document.title
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        sx={{
                                            '& .MuiInputBase-root': {
                                                fontSize: '1.4rem', 
                                                borderRadius: '0.4rem',
                                                paddingLeft: '1rem!important',
                                            }
                                        }}
                                        label=""
                                        placeholder="Søk etter artikkel"
                                        onChange={(e) => searchString(e.target.value)}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <InputAdornment sx={{marginRight: '1rem'}} position="end">
                                                    <i className="ri-search-line"/>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                )}
                                renderOption={(props, option) => {
                                    if (typeof option === 'string') {
                                        return (
                                            <li {...props}>
                                                <Grid className="search-option" container alignItems="center">
                                                    <span className="option-title">{option}</span>
                                                </Grid>
                                            </li>
                                        )
                                    }
                                    return (
                                        <li {...props}>
                                            <Grid className="search-option" container alignItems="center">
                                                <span className="option-title">{option.document.title}</span>
                                                <span className="option-description" dangerouslySetInnerHTML={{ __html: option.highlights[0].snippet }}>
                                                </span>
                                            </Grid>
                                        </li>
                                    )
                                }}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}