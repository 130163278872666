import { ExtendedRecordMap } from 'notion-types'

const cache = new Map<string, ExtendedRecordMap>();

export default async function fetchNotionData(blockId: string, controller: any): Promise<ExtendedRecordMap> {
    const cachedData = cache.get(blockId)

    if (cachedData) {
        return cachedData
    } else {
        const response = await fetch(`/api/support/v2/${encodeURIComponent(blockId)}`, {
            signal: controller.signal
        }).catch((error) => {
            throw new Error(`Failed to load Notion page: ${error}`)
        })
        if (!response.ok) {
            throw new Error(`Failed to load Notion page: ${response.statusText}`)
        }
        const data = await response.json() as ExtendedRecordMap
        cache.set(blockId, data)
        return data
    }
}
