import { Drawer, useMediaQuery } from "@mui/material"
import { theme } from "../theme/theme"
import { MenuList } from "./MenuList"
type Props = {
    menuOpen: boolean
    setMenuOpen: (open: boolean) => void
    urls: { [notionId: string]: string }
}

export const SideMenu = ({setMenuOpen, menuOpen, urls} : Props) => {
    const mobile = useMediaQuery(theme.breakpoints.down('md'))
    return (
        <Drawer sx={{'& .MuiPaper-root': { marginTop: '7.1rem'}}} variant={mobile ? 'temporary' : 'permanent'} open={mobile ? menuOpen : true}>
            <MenuList setMenuOpen={setMenuOpen} urls={urls}/>
        </Drawer>
    )
}
