import {ThemeProvider} from '@mui/material';
import {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes } from 'react-router-dom';
import 'remixicon/fonts/remixicon.css';
import "./App.scss";
import Home from './Home';
import {Main} from './Main';
import SupportArticle from './SupportArticle/SupportArticle';
import {theme} from './theme/theme';
import {loadHubspot} from './tracking/Hubspot';


function App() {
    const [urls, setUrls] = useState<{ [notionId: string]: string }>({});

    const getRedirects = (controller: any, data: any) => {
        fetch('/api/redirects',
        {
            signal: controller.signal,
        }).then((response) => response.json()).then((r) => {
            const currentPath = decodeURI(window.location.pathname)
            if (r[currentPath]) {
                const pageId = r[currentPath]
                const newPath = '/' + data[pageId] || '/' + pageId
                if (currentPath !== newPath) {
                    window.location.pathname = newPath
                }
            }
        }).catch((e) => {
            console.error(e)
        })
    }

    useEffect(() => {
        const urlController = new AbortController();
        const redirectController = new AbortController();
        fetch('/api/urls', { signal: urlController.signal }).then((response) => response.json()).then((data) => {
            setUrls(data)
            getRedirects(redirectController, data)
        }).catch((e) => {
            console.error(e)
        })
        loadHubspot()

        return () => {
            redirectController?.abort()
            urlController?.abort()
        }
    }, [])

    if (Object.keys(urls).length === 0) {
        return null
    }

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Main urls={urls} element={<Home urls={urls}/>}/>}/>
                    <Route path="/:id?/*" element={<Main urls={urls} element={<SupportArticle urls={urls}/>}/>}/>
                    <Route path="/no-no/*" element={<ReDir/>}></Route>
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
}

const ReDir = () => {
    useEffect(() => {
        window.location.href = window.location.href.replace('/no-no/', '/')
    }, [])
    return null
}

export default App;
